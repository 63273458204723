import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { isAddress } from '../../utils/index.js'
import EthereumLogo from '../../assets/eth.png'

import { useChainSelected } from '../../contexts/GlobalData'

const BAD_IMAGES = {}

const Inline = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`

const Image = styled.img`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
`

const StyledEthereumLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: ${({ size }) => size};
    height: ${({ size }) => size};
  }
`

export default function TokenLogo({ address, header = false, size = '24px', ...rest }) {
  const [error, setError] = useState(false)

  const chainSelected = useChainSelected()




  //console.log('chainSelected', chainSelected.tokenList)


  useEffect(() => {
    /*
        let founded = false;
        let chainTokenList = chainSelected.tokenList;
        if (chainTokenList) {
          chainTokenList.map((m) => {
            if (m.address.toLowerCase() == address?.toLowerCase()) {
              setTokenData(m)
              setError(false)
              founded = true;
            }
          });
        } else {
          setTokenData(false)
        }
    
        if (!founded) {
          if (address?.toLowerCase() != chainSelected.wethAddress) {
            setError(true)
          } else {
            setError(false)
          }
        }
    
        console.log('tokenData', tokenData)
    */
    setError(false)

  }, [address])




  if (error || BAD_IMAGES[address]) {
    return (
      <Inline>
        <span {...rest} alt={''} style={{ fontSize: size }} role="img" aria-label="face">
          🤔
        </span>
      </Inline>
    )
  }



  if (address?.toLowerCase() === chainSelected.wethAddress) {
    return (
      <StyledEthereumLogo size={size} {...rest}>
        <img
          src={chainSelected.imageUrl}
          style={{
            boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.075)',
            borderRadius: '24px',
          }}
          alt=""
        />
      </StyledEthereumLogo>
    )
  }

  /*
    const path = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${isAddress(
      address
    )}/logo.png`
  */

  const path = chainSelected.getTokenImgOfList(address);

  return (
    <Inline>
      <Image
        {...rest}
        alt={''}
        src={path}
        size={size}
        onError={(event) => {
          BAD_IMAGES[address] = true
          setError(true)
          event.preventDefault()
        }}
      />
    </Inline>
  )


}
