import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ApolloProvider } from 'react-apollo'
import { client } from './apollo/client'
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom'
import GlobalPage from './pages/GlobalPage'
import TokenPage from './pages/TokenPage'
import PairPage from './pages/PairPage'
import Link from './components/Link'
import { useGlobalData, useGlobalChartData, useChainSelected } from './contexts/GlobalData'
import { isAddress } from './utils'
import AccountPage from './pages/AccountPage'
import AllTokensPage from './pages/AllTokensPage'
import AllPairsPage from './pages/AllPairsPage'
import PinnedData from './components/PinnedData'

import SideNav from './components/SideNav'
import AccountLookup from './pages/AccountLookup'
import Meta from './components/Meta'
import LocalLoader from './components/LocalLoader'
import { useLatestBlocks } from './contexts/Application'
//import GoogleAnalyticsReporter from './components/analytics/GoogleAnalyticsReporter'
import { PAIR_BLACKLIST, TOKEN_BLACKLIST } from './constants'

import { chainsInfo } from './constants'


const AppWrapper = styled.div`
  position: relative;
  width: 100%;
`
const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ open }) => (open ? '220px 1fr 200px' : '220px 1fr 64px')};

  @media screen and (max-width: 1400px) {
    grid-template-columns: 220px 1fr;
  }

  @media screen and (max-width: 1080px) {
    grid-template-columns: 1fr;
    max-width: 100vw;
    overflow: hidden;
    grid-gap: 0;
  }
`

const Right = styled.div`
  position: fixed;
  right: 0;
  bottom: 0rem;
  z-index: 99;
  width: ${({ open }) => (open ? '220px' : '64px')};
  height: ${({ open }) => (open ? 'fit-content' : '64px')};
  overflow: auto;
  background-color: ${({ theme }) => theme.bg1};
  @media screen and (max-width: 1400px) {
    display: none;
  }
`

const Center = styled.div`
  height: 100%;
  z-index: 9999;
  transition: width 0.25s ease;
  background-color: ${({ theme }) => theme.onlyLight};
`

const BannerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const WarningBanner = styled.div`
  background-color: #ff6871;
  padding: 1.5rem;
  color: white;
  width: 100%;
  text-align: center;
  font-weight: 500;
`

const UrlBanner = styled.div`
  background-color: #ff007a;
  padding: 1rem;
  color: white;
  width: 100%;
  text-align: center;
  font-weight: 500;
`

const Decorator = styled.span`
  text-decoration: underline;
`

/**
 * Wrap the component with the header and sidebar pinned tab
 */
const LayoutWrapper = ({ children, savedOpen, setSavedOpen }) => {
  return (
    <>
      <ContentWrapper open={savedOpen}>
        <Meta />
        <SideNav />
        <Center id="center">{children}</Center>
        <Right open={savedOpen}>
          <PinnedData open={savedOpen} setSavedOpen={setSavedOpen} />
        </Right>
      </ContentWrapper>
    </>
  )
}

const BLOCK_DIFFERENCE_THRESHOLD = 30





function App() {

  const chainSel = useChainSelected();

  const [savedOpen, setSavedOpen] = useState(false)

  const globalData = useGlobalData()
  const globalChartData = useGlobalChartData()
  const [latestBlock, headBlock] = useLatestBlocks()
  //const [chainSel, setChainSel] = useState(null);


  // show warning
  const showWarning = headBlock && latestBlock ? headBlock - latestBlock > BLOCK_DIFFERENCE_THRESHOLD : false

  return (
    <ApolloProvider client={client}>
      <AppWrapper>
        <BannerWrapper>
          <UrlBanner>
            {chainSel.selected != null ? `Viewing ${chainSel.selected.name} network information - ` : null}{`Verify that you are at `}
            <Link color="white" external href={'https://info.kinderswap.finance'}>
              <Decorator>info.kinderswap.finance</Decorator>
            </Link>{' '}
          </UrlBanner>
        </BannerWrapper>
        {showWarning && (
          <BannerWrapper>
            <WarningBanner>
              {`Warning: The data on this site has only synced to Ethereum block ${latestBlock} (out of ${headBlock}). Please check back soon.`}
            </WarningBanner>
          </BannerWrapper>
        )}
        {globalData &&
          Object.keys(globalData).length > 0 &&
          globalChartData &&
          Object.keys(globalChartData).length > 0 ? (
          <BrowserRouter>
            <Route />
            <Switch>
              <Route
                exacts
                strict
                path="/token/:tokenAddress/:chainSuf?"
                render={({ match }) => {
                  chainSel.handleChange(match.params.chainSuf);
                  if (
                    isAddress(match.params.tokenAddress.toLowerCase()) &&
                    !Object.keys(TOKEN_BLACKLIST).includes(match.params.tokenAddress.toLowerCase())
                  ) {
                    return (
                      <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                        <TokenPage chainSel={chainSel.selected} address={match.params.tokenAddress.toLowerCase()} />
                      </LayoutWrapper>
                    )
                  } else {
                    return <Redirect to={"/" + chainSel.selected.urlName + "/overview"} />
                  }
                }}
              />
              <Route
                exacts
                strict
                path="/pair/:pairAddress/:chainSuf?"
                render={({ match }) => {
                  chainSel.handleChange(match.params.chainSuf);
                  if (
                    isAddress(match.params.pairAddress.toLowerCase()) &&
                    !Object.keys(PAIR_BLACKLIST).includes(match.params.pairAddress.toLowerCase())
                  ) {
                    return (
                      <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                        <PairPage chainSel={chainSel.selected} pairAddress={match.params.pairAddress.toLowerCase()} />
                      </LayoutWrapper>
                    )
                  } else {
                    return <Redirect to={"/" + chainSel.selected.urlName + "/overview"} />
                  }
                }}
              />
              <Route
                exacts
                strict
                path="/account/:accountAddress/:chainSuf?"
                render={({ match }) => {
                  chainSel.handleChange(match.params.chainSuf);
                  if (isAddress(match.params.accountAddress.toLowerCase())) {
                    return (
                      <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                        <AccountPage chainSel={chainSel.selected} account={match.params.accountAddress.toLowerCase()} />
                      </LayoutWrapper>
                    )
                  } else {
                    return <Redirect to={"/" + chainSel.selected.urlName + "/overview"} />
                  }
                }}
              />

              <Route path="/overview/:chainSuf?" render={({ match }) => {
                //const chainSel = chainsInfo(match.params.chainSuf);
                //setChainSel(chainSel);
                chainSel.handleChange(match.params.chainSuf);
                return (
                  <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                    <GlobalPage chainSel={chainSel.selected} />
                  </LayoutWrapper>
                )
              }} />

              <Route path="/tokens/:chainSuf?" render={({ match }) => {
                chainSel.handleChange(match.params.chainSuf);
                return (
                  <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                    <AllTokensPage chainSel={chainSel.selected} />
                  </LayoutWrapper>
                )
              }} />

              <Route path="/pairs/:chainSuf?" render={({ match }) => {
                chainSel.handleChange(match.params.chainSuf);
                return (
                  <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                    <AllPairsPage chainSel={chainSel.selected} />
                  </LayoutWrapper>
                )
              }} />



              {/* 
              <Route path="/accounts">
                <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                  <AccountLookup />
                </LayoutWrapper>
              </Route>
*/}
              <Redirect to="/overview" />
            </Switch>
          </BrowserRouter>
        ) : (
          <LocalLoader fill="true" func={() => {
            let urlParts = window.location.pathname.split('/');
            let tryChain = urlParts[urlParts.length - 1];
            //console.log("tryChain", tryChain);
            chainSel.handleChange(tryChain);
          }} />
        )}
      </AppWrapper>
    </ApolloProvider>
  )
}

export default App
