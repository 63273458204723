
import { chainCompatible, chainCompatibleArraySuf, chainCompatibleTry } from './chainCompatible';


export const locationOrigin = window.location.origin;


export const LOGO_URL = locationOrigin + '/assets/img/kinderswap.png'

export const LOGO_ISO_URL = locationOrigin + '/assets/img/iso_kinderswap.png'

export const LOGO_TEXT_URL = locationOrigin + '/assets/img/text_kinderswap.png'

export const defaultChain = 'GSYS';

export const chainsInfo = (suffix) => {
  if (suffix) {
    let chainSel = chainCompatibleTry((suffix.toUpperCase()));
    if (!chainSel.compatible) {
      chainSel = chainCompatibleTry(defaultChain);
    }
    let urlParts = window.location.pathname.split('/');
    urlParts.pop();
    window.history.pushState('', '', locationOrigin + urlParts.join('/') + '/' + chainSel.urlName);

    return chainSel;

  } else {
    const chainSel = chainCompatibleTry(defaultChain);
    const urlParts = window.location.pathname.split('/');
    if (!urlParts.includes(chainSel.urlName)) {
      const separator = urlParts[urlParts.length - 1] === '' ? '' : '/';
      window.history.pushState('', '', window.location.href + separator + chainSel.urlName);
    }
    return chainSel;
  }
}


export const BEGIN_BLOCK = { //first pair added
  1: 0,
  56: 0,
  97: 0,
  137: 0,
  42440: 0,
  16507: 8636694,
  13600: 13062519,
}


export const BEGIN_TIMESTAMP = { //first pair added
  1: 0,
  56: 0,
  97: 0,
  137: 0,
  42440: 0,
  16507: 1718559920,
  13600: 1718562431,
}


//export const FACTORY_ADDRESS = '0x93f0029c4541ad121e4e33a6709e9b2e2a9026e4'
export const FACTORY_ADDRESS = {
  1: '',
  56: '0x52954a9d6c3507a11025c99c22fff36746cb3c68',
  97: '',
  137: '',
  42440: '',
  16507: '0x6740a40e4a9d2ab697dbdb82bad4e2754b68d6f9',
  13600: '0x87ac1b1e3879ace5cc1da0d5c49e9de317a8194f',
}

export const GET_FACTORY_ADDRESS = (chainId) => {
  return FACTORY_ADDRESS[chainId];
}

export const BUNDLE_ID = '1'

export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  // THREE_MONTHS: '3 months',
  // YEAR: '1 year',
  HALF_YEAR: '6 months',
  ALL_TIME: 'All time',
}

// token list urls to fetch tokens from - use for warnings on tokens and pairs

export const INFOTOKENSLISTS = {
  1: [
    'https://raw.githubusercontent.com/Powabit/kinderswap-tokenlist/main/eth.json',
  ],
  56: [
    'https://raw.githubusercontent.com/Powabit/kinderswap-tokenlist/main/bsc.json',
  ],
  97: [
    'https://raw.githubusercontent.com/Powabit/kinderswap-tokenlist/main/bsc_testnet.json',
  ],
  137: [
    'https://raw.githubusercontent.com/Powabit/kinderswap-tokenlist/main/matic.json',
  ],
  42440: [
    'https://raw.githubusercontent.com/Powabit/kinderswap-tokenlist/main/gsys-testnet.json',
  ],
  16507: [
    'https://raw.githubusercontent.com/Powabit/kinderswap-tokenlist/main/gsys.json',
  ],
  13600: [
    'https://raw.githubusercontent.com/Powabit/kinderswap-tokenlist/main/knb.json',
  ],
}

export const SUPPORTED_LIST_URLS__NO_ENS = [
  'https://raw.githubusercontent.com/Powabit/kinderswap-tokenlist/main/gsys.json',
]

// hide from overview list
export const TOKEN_BLACKLIST = [
  '0x495c7f3a713870f68f8b418b355c085dfdc412c3',
  '0xc3761eb917cd790b30dad99f6cc5b4ff93c4f9ea',
  '0xe31debd7abff90b06bca21010dd860d8701fd901',
  '0xfc989fbb6b3024de5ca0144dc23c18a063942ac1',
  '0xf4eda77f0b455a12f3eb44f8653835f377e36b76',
  '0x93b2fff814fcaeffb01406e80b4ecd89ca6a021b',

  // rebass tokens
  '0x9ea3b5b4ec044b70375236a281986106457b20ef',
  '0x05934eba98486693aaec2d00b0e9ce918e37dc3f',
  '0x3d7e683fc9c86b4d653c9e47ca12517440fad14e',
  '0xfae9c647ad7d89e738aba720acf09af93dc535f7',
  '0x7296368fe9bcb25d3ecc19af13655b907818cc09',
]

// pair blacklist
export const PAIR_BLACKLIST = [
  '0xb6a741f37d6e455ebcc9f17e2c16d0586c3f57a5',
  '0x97cb8cbe91227ba87fc21aaf52c4212d245da3f8',
  '0x1acba73121d5f63d8ea40bdc64edb594bd88ed09',
  '0x7d7e813082ef6c143277c71786e5be626ec77b20',
]

// warnings to display if page contains info about blocked token
export const BLOCKED_WARNINGS = {
  '0xf4eda77f0b455a12f3eb44f8653835f377e36b76':
    'TikTok Inc. has asserted this token is violating its trademarks and therefore is not available.',
}

/**
 * For tokens that cause erros on fee calculations
 */
export const FEE_WARNING_TOKENS = ['0xd46ba6d942050d489dbd938a2c909a5d5039a161']

export const UNTRACKED_COPY = {
  1: 'Derived USD values may be inaccurate without liquid stablecoin or ETH pairings.',
  56: 'Derived USD values may be inaccurate without liquid stablecoin or BNB pairings.',
  97: 'Derived USD values may be inaccurate without liquid stablecoin or BNB pairings.',
  137: 'Derived USD values may be inaccurate without liquid stablecoin or MATIC pairings.',
  42440: 'Derived USD values may be inaccurate without liquid stablecoin or GSYS pairings.',
  16507: 'Derived USD values may be inaccurate without liquid stablecoin or GSYS pairings.',
}

// pairs that should be tracked but arent due to lag in subgraph
export const TRACKED_OVERRIDES_PAIRS = [
  '0x9928e4046d7c6513326ccea028cd3e7a91c7590a',
  '0x87da823b6fc8eb8575a235a824690fda94674c88',
  '0xcd7989894bc033581532d2cd88da5db0a4b12859',
  '0xe1573b9d29e2183b1af0e743dc2754979a40d237',
  '0x45804880de22913dafe09f4980848ece6ecbaf78',
  '0x709f7b10f22eb62b05913b59b92ddd372d4e2152',
]

// tokens that should be tracked but arent due to lag in subgraph
// all pairs that include token will be tracked
export const TRACKED_OVERRIDES_TOKENS = ['0x956f47f50a910163d8bf957cf5846d573e7f87ca']
