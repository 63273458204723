import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { chainSelected } from '../contexts/GlobalData'

import { chainsInfo } from '../constants'

export function getClientsUrl() {
  const urlParts = window.location.pathname.split('/');
  const tryChain = urlParts[urlParts.length - 1];
  const chainInfo = chainsInfo(tryChain);
  return {
    blockClientUrl: chainInfo.blockClientUrl,
    clientUrl: chainInfo.clientUrl,
  };
}


export const client = new ApolloClient({
  link: new HttpLink({
    //uri: 'http://localhost:8100/subgraphs/name/kinderswap/exchange-gsys',
    uri: getClientsUrl().clientUrl,
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})


export const healthClient = new ApolloClient({
  link: new HttpLink({
    //uri: 'http://localhost:8100/subgraphs/name/kinderswap/exchange-gsys',
    uri: getClientsUrl().clientUrl,
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

/*
export const healthClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/index-node/graphql',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})
*/
export const v1Client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const stakingClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/way2rach/talisman',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const blockClient = new ApolloClient({
  link: new HttpLink({
    //uri: 'http://localhost:8100/subgraphs/name/kinderswap/blocks',
    uri: getClientsUrl().blockClientUrl,
  }),
  cache: new InMemoryCache(),
})
