
export const chainCompatible = {
    "ETH": {
        chainId: 1,
        name: 'Ethereum',
        nameCoin: 'Ether',
        symbol: 'ETH',
        decimals: 18,
        wrapped: {
            name: 'Wrapped ETH',
            symbol: 'WETH',
            decimals: 18,
            address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'
        },
        minName: 'ETH',
        urlName: 'ETH',
        rpc: 'https://mainnet.infura.io/v3/e1fe0d616b29495ab139715a748caf1e',
        rpcName: '',
        explorerName: 'Etherscan',
        explorerUrl: 'https://etherscan.io',
        explorerAddress: 'https://etherscan.io/address/',
        explorerToken: 'https://etherscan.io/token/',
        explorerTx: 'https://etherscan.io/tx/',
        networkType: 'mainnet',
        description: 'Ethereum is a decentralized platform that runs smart contracts: applications that run exactly as programmed without any possibility of downtime, fraud or third party interference.',
        coingeckoApiPrice: 'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd',
        coingeckoApiTicket: 'ethereum',
        clientUrl: '',
        blockClientUrl: '',
        compatible: false,
    },
    "BSC": {
        chainId: 56,
        name: 'Binance Smart Chain',
        minName: 'BNB',
        symbol: 'BNB',
        decimals: 18,
        wrapped: {
            name: 'Wrapped BNB',
            symbol: 'WBNB',
            decimals: 18,
            address: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'
        },
        nameCoin: 'Binance Coin',
        urlName: 'BSC',
        rpc: 'https://bsc-dataseed.binance.org',
        rpcName: 'binance',
        explorerName: 'BscScan',
        explorerUrl: 'https://bscscan.com',
        explorerAddress: 'https://bscscan.com/address/',
        explorerToken: 'https://bscscan.com/token/',
        explorerTx: 'https://bscscan.com/tx/',
        networkType: 'mainnet',
        description: 'Binance Smart Chain is a blockchain platform that allows developers to build decentralized applications on a fast and secure network.',
        coingeckoApiPrice: 'https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd',
        coingeckoApiTicket: 'binancecoin',
        clientUrl: 'https://bsc-api.kinderswap.finance/subgraphs/name/kinderswap/exchange-bsc',
        blockClientUrl: 'https://bsc-api.kinderswap.finance/subgraphs/name/kinderswap/blocks',
        compatible: false,
    },
    "BSC-TEST": {
        chainId: 97,
        name: 'Binance Smart Chain Testnet',
        nameCoin: 'Binance Coin',
        symbol: 'BNB',
        decimals: 18,
        wrapped: {
            name: 'Wrapped BNB',
            symbol: 'WBNB',
            decimals: 18,
            address: '0xae13d989dac2f0debff460ac112a837c89baa7cd'
        },
        minName: 'BNB TEST',
        urlName: 'BSC-TEST',
        rpc: 'https://data-seed-prebsc-1-s1.binance.org:8545',
        rpcName: 'binance',
        explorerName: 'BscScan',
        explorerUrl: 'https://testnet.bscscan.com',
        explorerAddress: 'https://testnet.bscscan.com/address/',
        explorerToken: 'https://testnet.bscscan.com/token/',
        explorerTx: 'https://testnet.bscscan.com/tx/',
        networkType: 'testnet',
        description: 'Binance Smart Chain is a blockchain platform that allows developers to build decentralized applications on a fast and secure network.',
        coingeckoApiPrice: 'https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd',
        coingeckoApiTicket: 'binancecoin',
        clientUrl: '',
        blockClientUrl: '',
        compatible: false,
    },
    "MATIC": {
        chainId: 137,
        name: 'Polygon',
        nameCoin: 'Matic',
        symbol: 'MATIC',
        decimals: 18,
        wrapped: {
            name: 'Wrapped Matic',
            symbol: 'WMATIC',
            decimals: 18,
            address: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270'
        },
        minName: 'MATIC',
        urlName: 'MATIC',
        rpc: 'https://rpc-mainnet.maticvigil.com/',
        rpcName: 'matic',
        explorerName: 'Polygonscan',
        explorerUrl: 'https://polygonscan.com',
        explorerAddress: 'https://polygonscan.com/address/',
        explorerToken: 'https://polygonscan.com/token/',
        explorerTx: 'https://polygonscan.com/tx/',
        networkType: 'mainnet',
        description: 'Polygon is a protocol and a framework for building and connecting Ethereum-compatible blockchain networks.',
        coingeckoApiPrice: 'https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd',
        coingeckoApiTicket: 'matic-network',
        clientUrl: '',
        blockClientUrl: '',
        compatible: false,
    },
    "GSYS-TEST": {
        chainId: 42440,
        name: 'Genesys Testnet',
        nameCoin: 'Genesys',
        symbol: 'GSYS',
        decimals: 18,
        wrapped: {
            name: 'Wrapped Genesys',
            symbol: 'WGSYS',
            decimals: 18,
            address: '0xe7e3e6adeb4bb38f7c58c42c698fa0293f957f24'
        },
        minName: 'GSYS TEST',
        urlName: 'GSYS-TEST',
        rpc: 'https://testnet-rpc.genesys.network',
        rpcName: 'genesys',
        explorerName: 'Gchain Explorer',
        explorerUrl: 'https://testnet-gchainexplorer.genesys.network',
        explorerAddress: 'https://testnet-gchainexplorer.genesys.network/address/',
        explorerToken: 'https://testnet-gchainexplorer.genesys.network/token/',
        explorerTx: 'https://testnet-gchainexplorer.genesys.network/tx/',
        networkType: 'testnet',
        description: 'Genesys is a protocol and a framework for building and connecting Ethereum-compatible blockchain networks.',
        coingeckoApiPrice: 'https://api.coingecko.com/api/v3/simple/price?ids=genesys&vs_currencies=usd',
        coingeckoApiTicket: 'genesys',
        clientUrl: '',
        blockClientUrl: '',
        compatible: false,
    },
    "GSYS": {
        chainId: 16507,
        name: 'Genesys Mainnet',
        nameCoin: 'Genesys',
        symbol: 'GSYS',
        decimals: 18,
        wrapped: {
            name: 'Wrapped Genesys',
            symbol: 'WGSYS',
            decimals: 18,
            address: '0xaa7ae83eb30dddd14a017d4222121776317ea8ba'
        },
        minName: 'GSYS',
        urlName: 'GSYS',
        rpc: 'https://rpc.genesys.network',
        rpcName: 'genesys',
        explorerName: 'Gchain Explorer',
        explorerUrl: 'https://gchainexplorer.genesys.network',
        explorerAddress: 'https://gchainexplorer.genesys.network/address/',
        explorerToken: 'https://gchainexplorer.genesys.network/token/',
        explorerTx: 'https://gchainexplorer.genesys.network/tx/',
        networkType: 'mainnet',
        description: 'Genesys is a protocol and a framework for building and connecting Ethereum-compatible blockchain networks.',
        coingeckoApiPrice: 'https://api.coingecko.com/api/v3/simple/price?ids=genesys&vs_currencies=usd',
        coingeckoApiTicket: 'genesys',
        clientUrl: 'https://gsys-api.kinderswap.finance/subgraphs/name/kinderswap/exchange-gsys',
        blockClientUrl: 'https://gsys-api.kinderswap.finance/subgraphs/name/kinderswap/blocks',
        //blockClientUrl: 'https://thegraph.blue20bridge.io/subgraphs/name/gsysswap/blocks',
        compatible: true,
    },
    "KNB": {
        chainId: 13600,
        name: 'Kronobit',
        nameCoin: 'Kronobit',
        symbol: 'KNB',
        decimals: 18,
        wrapped: {
            name: 'Wrapped Kronobit',
            symbol: 'WKNB',
            decimals: 18,
            address: '0x76e1790ddcc4427c3e71bee219863a5f4f998cb7'
        },
        minName: 'KNB',
        urlName: 'knb',
        rpc: 'https://mainnet-rpc.qbitscan.com',
        rpcName: 'kronobit',
        explorerUrl: 'https://explorer.qbitscan.com',
        explorerAddress: 'https://explorer.qbitscan.com/address/',
        explorerToken: 'https://explorer.qbitscan.com/token/',
        explorerTx: 'https://explorer.qbitscan.com/tx/',
        networkType: 'mainnet',
        description: 'Worlds First Educative Blockchain, Secure, Interoperable & Scalable Blockchain Ecosystem.',
        coingeckoApiPrice: 'https://api.coingecko.com/api/v3/simple/price?ids=kronobit&vs_currencies=usd',
        coingeckoApiTicket: 'kronobit',
        clientUrl: 'https://knb-api.kinderswap.finance/subgraphs/name/kinderswap/exchange-knb',
        blockClientUrl: 'https://knb-api.kinderswap.finance/subgraphs/name/kinderswap/blocks',
        compatible: true,
    },
};

export const chainCompatibleArraySuf = Object.keys(chainCompatible);

export const chainCompatibleTry = (chainSUF) => {
    if (chainSUF != undefined && chainSUF != null && Object.keys(chainCompatible).includes((chainSUF).toString())) {
        return chainCompatible[chainSUF];
    } else {
        return {
            chainId: 0,
            networkType: '',
            urlName: '',
            name: 'Unsupported',
            minName: 'Unsupported',
            nameCoin: '',
            symbol: '',
            decimals: 18,
            wrappedName: '',
            wrappedSymbol: '',
            wrappedDecimals: 18,
            wrappedContract: '',
            rpc: '',
            rpcName: '',
            explorerUrl: '',
            compatible: false,
        }
    }
};


