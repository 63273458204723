


interface BasicData {
  token0?: {
    id: string
    name: string
    symbol: string
  }
  token1?: {
    id: string
    name: string
    symbol: string
  }
}

// Override data return from graph - usually because proxy token has changed
// names since entitiy was created in subgraph
// keys are lowercase token addresses <--------
const TOKEN_OVERRIDES: { [address: string]: { name: string; symbol: string } } = {
  //HARDCODED
  '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2': { // WETH
    name: 'Ether (Wrapped)',
    symbol: 'ETH',
  },
  '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c': { // BNB
    name: 'BNB (Wrapped)',
    symbol: 'BNB',
  },
  '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270': { // MATIC
    name: 'MATIC (Wrapped)',
    symbol: 'MATIC',
  },
  '0x4200000000000000000000000000000000000006': { // WETH BASE
    name: 'ETH (Wrapped)',
    symbol: 'ETH',
  },
  '0xaa7ae83eb30dddd14a017d4222121776317ea8ba': { // GSYS CAMBIAR
    name: 'GSYS (Wrapped)',
    symbol: 'GSYS',
  },
  '0x76e1790ddcc4427c3e71bee219863a5f4f998cb7': { // KNB
    name: 'KNB (Wrapped)',
    symbol: 'KNB',
  },
  '0xae13d989dac2f0debff460ac112a837c89baa7cd': { // BNB testnet
    name: 'BNB (Wrapped)',
    symbol: 'BNB',
  },
  '0xe7e3e6adeb4bb38f7c58c42c698fa0293f957f24': { // GSYS testnet
    name: 'GSYS (Wrapped)',
    symbol: 'GSYS',
  },
}

// override tokens with incorrect symbol or names
export function updateNameData(data: BasicData): BasicData | undefined {
  if (data?.token0?.id && Object.keys(TOKEN_OVERRIDES).includes(data.token0.id)) {
    data.token0.name = TOKEN_OVERRIDES[data.token0.id].name
    data.token0.symbol = TOKEN_OVERRIDES[data.token0.id].symbol
  }

  if (data?.token1?.id && Object.keys(TOKEN_OVERRIDES).includes(data.token1.id)) {
    data.token1.name = TOKEN_OVERRIDES[data.token1.id].name
    data.token1.symbol = TOKEN_OVERRIDES[data.token1.id].symbol
  }

  return data
}
